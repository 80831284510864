
// #################################################
//  Use btn/body className and number default active tab
// example: new Tab('.btn', '.body', {
//      activeNumber: 0,
// })
// #################################################

export class Tab {
    /**
     * @namespace Tab
     * @param {String} btnSelector - any "btn" selector
     * @param {String} bodySelector - any "body" selector
     */
    constructor(btnSelector, bodySelector, options = {
        /**
         * @property {Element|document} Define parent selector to your "tab"
         */
        parentSelector: document,
        /**
         * @property {number|null} Define number an active tab by default
         */
        activeNumber: null,
        /**
         * @property {boolean} Property "useClass" must be defined. Property "useClass" will be concat to your "body & btn" selectors, they must be classes.
         */
        classListLikeBem: false,
        /**
         * @property {boolean|string} Define added class to your "body & btn" selectors
         */
        useClass: false,
        /**
         * @property {boolean} Define, will be "tab" toggle on click
         */
        selfClose: false,
    }) {
        this.btnSelector = btnSelector
        this.bodySelector = bodySelector
        this.parentSelector = options.parentSelector
        this.activeNumber = options.activeNumber
        this.useClass = options.useClass
        this.classListLikeBem = options.classListLikeBem
        this.selfClose = options.selfClose
        this.init()
    }

    init() {
        if(this.getParent()) {
            // проверяет  существование элементов, а также равное ли их количество
            if(this.getBtns().length > 0 && this.getBtns().length == this.getBodys().length) {
                this.getActiveNumber()
                this.getBtns().forEach((btn, key) => this.main(btn, this.getBodys()[key]))
            }
        }
    }

    getBtns() {
        const allBtns = this.getParent().querySelectorAll(this.btnSelector)
        return allBtns
    }

    getBodys() {
        const allBody = this.getParent().querySelectorAll(this.bodySelector)
        return allBody
    }

    getParent() {
        if(this.parentSelector) {
            return document.querySelector(this.parentSelector) || false
        } else {
            return document
        }
    }

    getActiveNumber() {
        if(!isNaN(parseInt(this.activeNumber))) {
            // проверяет является ли заданное число больше длинны элементов (отсчет элементов идет с нуля, а длинны с единицы)
            if(this.activeNumber == this.getBtns().length) {
                throw new Error('Active number can\'t be biggest than btn lenght')
            } else {
                this.show(this.getBtns()[this.activeNumber],this.getBodys()[this.activeNumber])
            }
        } else {
            return false
        }
    }

    getBemClass(selector) {
        if(this.classListLikeBem) {
            return selector.slice(1)
        }
    }

    main(btn, body) {
        btn.addEventListener('click', (event) => {
            event.preventDefault();
            this.onClick(btn, body)
        })
    }

    onClick(btn, body) {
        if(this.selfClose) {
            this.toggle(btn, body)
        } else {
            this.getBtns().forEach((el, key) => {
                this.hide(el, this.getBodys()[key])
            })
            this.show(btn, body)
        }
    }

    show(btn, body) {
        if(this.useClass) {
            if(this.classListLikeBem) {
                btn.classList.add(`${this.getBemClass(this.btnSelector)}${this.useClass}`)
                body.classList.add(`${this.getBemClass(this.bodySelector)}${this.useClass}`)
            } else {
                btn.classList.add(this.useClass)
                body.classList.add(this.useClass)
            }
        } else {
            btn.classList.add('show')
            body.style.display = 'block'
        }
    }

    hide(btn, body) {
        if(this.useClass) {
            if(this.classListLikeBem) {
                btn.classList.remove(`${this.getBemClass(this.btnSelector)}${this.useClass}`)
                body.classList.remove(`${this.getBemClass(this.bodySelector)}${this.useClass}`)
            } else {
                btn.classList.remove(this.useClass)
                body.classList.remove(this.useClass)
            }
        } else {
            btn.classList.remove('show')
            body.style.display = 'none'
        }
    }

    toggle(btn, body) {
        if(this.useClass) {
            if(this.classListLikeBem) {
                btn.classList.toggle(`${this.getBemClass(this.btnSelector)}${this.useClass}`)
                body.classList.toggle(`${this.getBemClass(this.bodySelector)}${this.useClass}`)
            } else {
                btn.classList.toggle(this.useClass)
                body.classList.toggle(this.useClass)
            }
        } else {
            btn.classList.toggle('show')
            if(body.style.display == 'none') {
                body.style.display = 'block'
            } else {
                body.style.display = 'none'
            }
        }
    }
}
