require('../scss/app.scss');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');

// Зависимости
import "@fortawesome/fontawesome-free/js/all";

require('./default/script');
require('./default/_video');

require('./default/shops');
