require('./long-text')
require('./shops')
import { Tab } from "./_Tab_class";

window.addEventListener('DOMContentLoaded', () => {
    new Tab('[data-btn]', '[data-body]', {
        parentSelector: '.tabs',
        activeNumber: 0,
        useClass: 'active',
    })
})
require('./_swiper')

