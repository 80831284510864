window.addEventListener('DOMContentLoaded',() => start('[data-long-text]'))

function start(elementSelector) {
    const elements = document.querySelectorAll(elementSelector)

    if(elements.length > 0) {
        elements.forEach(element => {
            longText(element.querySelector('p'), element.dataset.longText)
        });
    }
}

function longText(parent, maxLenght) {

    let text = parent.textContent
    parent.textContent = ''

    if(text.length > maxLenght) {
        let showText = text.slice(0, maxLenght)
        let hideText = text.slice(maxLenght)

        spanText(parent, showText)
        moreTextBtn(parent, hideText)
        addDots(parent)
    }

    function createBtn(text, elClass) {
        let e = document.createElement('button')
        elClass ? e.classList.add(elClass) : false
        e.textContent = text
        return e
    }

    function moreTextBtn(parent, hideText) {
        const btn = createBtn('ПОДРОБНЕЕ', 'show')

        btn.addEventListener('click', () => click())
        parent.insertAdjacentElement('beforeend', btn)

        function click() {
            btn.remove()
            spanText(parent, hideText)
            hideTextBtn(parent, hideText)
            removeDots(parent)
        }
    }

    function hideTextBtn(parent, hideText) {
        const btn = createBtn('СКРЫТЬ', 'hide')
        btn.addEventListener('click', () => {
            btn.previousElementSibling.remove()
            btn.remove()
            moreTextBtn(parent, hideText)
            addDots(parent)
        })
        parent.insertAdjacentElement('beforeend', btn)
    }

    function spanText(parent, textWithoutSpan) {
        const span = document.createElement('span')
        span.textContent = textWithoutSpan

        parent.insertAdjacentElement('beforeend', span)
    }

    function addDots(parent) {
        parent.firstElementChild.textContent += '...'
    }
    function removeDots(parent) {
        parent.firstElementChild.textContent = parent.firstElementChild.textContent.slice(0, -3)
    }
}

