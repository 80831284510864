window.addEventListener('load', () => {
    loadPlayer()
})

// загрузчик для youtube
function loadPlayer() {
    if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {

        let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";

    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubePlayerAPIReady = function() {
        onYouTubePlayerReady();
    };

    } else {
        onYouTubePlayerReady();
    }
}

function onYouTubePlayerReady() {

    const videos = document.querySelectorAll('[data-video]')
    const videoPosters = document.querySelectorAll('[data-poster]')

    if(videos.length > 0 && videos.length == videoPosters.length) {
        videoPosters.forEach((poster, key) => {


            if(isAutoplay(videos[key])) {
                initializePlayer(poster, key, 1)
            } else {
                poster.addEventListener('click', () => {
                    initializePlayer(poster, key)
                })
            }

        })
    }

    function initializePlayer(poster, key, value = 0) {
        removePoster(poster)

        let player = new YT.Player(getElemId(videos[key]), {
            height: '100%',
            width: '100%',
            playerVars: {
                // Значения: 0 или 1. Значение по умолчанию: 0. Определяет, начинается ли воспроизведение исходного видео сразу после загрузки проигрывателя.
                'autoplay': value,
                // Значения: 0 или 1. Значение по умолчанию: 0. Если значение равно 1, то одиночный проигрыватель будет воспроизводить видео по кругу, в бесконечном цикле. Проигрыватель плейлистов (или пользовательский проигрыватель) воспроизводит по кругу содержимое плейлиста.
                'loop': value,
                // Значение представляет собой разделенный запятыми список идентификаторов видео для воспроизведения. Если вы указываете значение, сначала воспроизводится видео, указанное как VIDEO_ID в URL, а затем видео, указанные в параметре playlist.
                'playlist': getVideoId(videos[key]),
                // Значения: 0 или 1. Значение по умолчанию: 1. Этот параметр определяет, будут ли воспроизводиться похожие видео после завершения показа исходного видео.
                'rel': 0,
                // Значения: 1 или 3. Значение по умолчанию: 1. При значении 1 аннотации видео по умолчанию будут отображаться, а при значении 3 – по умолчанию будут скрыты.
                'iv_load_policy': 3,
                // Значения: 0 или 1. Значение по умолчанию: 1. При значении 0 проигрыватель перед началом воспроизведения не выводит информацию о видео, такую как название и автор видео. Если вы устанавливаете значение 1, после загрузки плейлиста в проигрывателе отображаются значки всех видео в списке. Эта функция поддерживается только в проигрывателе AS3, который позволяет загружать плейлисты.
                'showinfo': 0,
            },
            videoId: getVideoId(videos[key]),
            events: {
                'onReady': onPlayerReady,
            }
        });
    }

    function removePoster(poster) {
        setTimeout(() => {
            document.querySelector('.header__top-bg').style.display = 'none'
            poster.style.display = 'none'
        }, 1500);
    }

    function isAutoplay(video) {
        return video.dataset.autoplay
    }

    function getElemId(elem) {
        return elem.id
    }

    function getVideoId(elem) {
        return elem.dataset.video
    }

    function onPlayerReady(event) {
        if(event.target.m.dataset.autoplay) {
            event.target.mute();
        }
        event.target.playVideo();
    }
}
