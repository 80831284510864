// js для свайпера, подключается блоками
// подробнее тут <https://swiperjs.com/get-started>
import SwiperCore, { Swiper, Pagination, Navigation} from "swiper/core";
SwiperCore.use([Swiper, Pagination, Navigation]);

// стили для свайпера
// брать тут <https://swiperjs.com/swiper-api#styles>, только для нужных компонентов
import "swiper/swiper.min.css";
// import "swiper/components/pagination/pagination.min.css";
// import "swiper/components/navigation/navigation.min.css";

window.addEventListener("DOMContentLoaded", () => {
    // ########################################################
    // swiper-technology
    if (document.querySelector(".swiper-container-technology")) {
        new Swiper(".swiper-container-technology", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            slidesPerColumnFill: 'row',

            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    spaceBetween: 20,
                },
                600: {
                    slidesPerView: 2,
                    slidesPerColumn: 1,
                    spaceBetween: 30,
                },
                900: {
                    slidesPerView: 3,
                    slidesPerColumn: 2,
                    spaceBetween: 40,
                },
                1200: {
                    slidesPerView: 3,
                    slidesPerColumn: 2,
                    spaceBetween: 80,
                }
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            pagination: {
                type: 'fraction',
                el: '.slider__fraction',
            }
        });
    }
    if (document.querySelector(".swiper-container-shop")) {
        new Swiper(".swiper-container-shop", {
            // Вкл\откл свайпов на пк
            simulateTouch: true,
            // Курсор перетаскивания
            grabCursor: true,

            watchOverflow: true,
            centerInsufficientSlides: true,
            // Мобайл-first подход (min-width: ...)
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                600: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
            },

            // // ========================================================
            // Включить предзагрузку изображений
            preloadImages: false,
            // Ленивая загрузка
            lazy: {
                // Начало загрузки при свайпе
                loadOnTransitionStart: true,
                // Подгружать prev/next картинки
                loadPrevNext: true,
            },

            // Слежка за видимыми слайдами
            watchSlidesProgress: true,
            // Добавление класса видимым сладам
            watchSlidesVisibility: true,

            navigation: {
                prevEl: '.shop__btn_prev',
                nextEl: '.shop__btn_next',
            }
        });
    }
});
